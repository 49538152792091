<template>
  <MenusUserNav>
    <Button variant="outline" class="w-full justify-start">
      <div class="flex w-full items-center">
        <Avatar size="xs" class="-my-1 mr-2 bg-secondary">
          <AvatarFallback>{{ fallackLetters }}</AvatarFallback>
          <AvatarImage
            v-if="auth.avatar.url"
            :src="auth.avatar.url"
            :alt="auth.username"
          />
        </Avatar>
        <span class="flex-grow truncate text-left text-sm font-semibold">
          {{ computedUsername }}
        </span>
      </div>
    </Button>
  </MenusUserNav>
</template>

<script setup lang="ts">
const auth = useAuthStore();

const fallackLetters = computed(() => auth.username?.[0]);

const computedUsername = computed(() => {
  if (auth.username?.startsWith("sl_id_")) {
    return "Streamlabs ID";
  }
  return auth.username;
});
</script>
