<template>
  <div class="flex w-full flex-col items-center">
    <div class="grid w-full max-w-3xl grid-cols-3 grid-rows-2 gap-3">
      <LibraryItem v-for="(overlay, i) in overlays" :key="i" class="cursor-default select-none" :class="{
        'outline outline-2 outline-primary':
          overlay && overlay.id === selectedOverlayId,
        'hoverpop cursor-pointer':
          overlay && overlay.id !== selectedOverlayId,
      }" :item="overlay || undefined" :hide-additional-images="true" :hide-designer="true"
        @click="overlay ? (selectedOverlayId = overlay.id) : undefined" />
    </div>
    <div class="mb-6 mt-8 text-center text-sm font-medium leading-relaxed text-muted-foreground">
      {{ $t("Or browse from thousands more overlays after onboarding") }}
    </div>
  </div>
</template>
<script setup lang="ts">
import { useVModel } from "@vueuse/core";
import streamlabs from "~/lib/streamlabs-api";

const browse = streamlabs.fetchOverlaysBrowse();

const overlays = computed(() => {
  if (browse.data.value) {
    return browse.data.value.data.slice(0, 9);
  }

  return Array.apply(null, Array(9)).map(x => null);
});

const props = defineProps<{
  selectedOverlayId: number | undefined;
}>();

const emit = defineEmits(["update:paypalEmail"]);

const selectedOverlayId = useVModel(props, "selectedOverlayId", emit);
</script>
