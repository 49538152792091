<template>
  <UiPopover v-model:open="isOpen">
    <UiPopoverTrigger as-child>
      <UiButton
        variant="secondary"
        role="combobox"
        :aria-expanded="isOpen"
        class="w-full justify-between px-3 text-[14px] font-normal"
        size="default"
      >
        {{ selectedOption?.label ?? placeholder }}
        <IconDropdown class="ml-2 h-4 w-4 shrink-0 opacity-50" />
      </UiButton>
    </UiPopoverTrigger>

    <UiPopoverContent class="max-h-18 p-0" side="bottom" align="end">
      <UiCommand
        :filter-function="
          (...args) => filterFunction(...(args as unknown as [T[], string]))
        "
      >
        <UiCommandInput
          :placeholder="placeholder"
          @input="($event) => emit('input', $event)"
          @keydown.esc="
            () => {
              isOpen = false;
            }
          "
        />
        <slot name="empty">
          <UiCommandEmpty>Nothing found.</UiCommandEmpty>
        </slot>

        <UiCommandList>
          <UiCommandGroup>
            <UiCommandItem
              v-for="option in sortedOptions"
              :key="option.value"
              :value="option"
              @select="
                () => {
                  value = option.value;
                  isOpen = false;
                }
              "
            >
              <div
                class="flex items-center space-x-3"
                :class="{ 'text-primary': value === option.value }"
              >
                <Icon
                  size="1.25em"
                  name="lucide:check"
                  class="flex-shrink-0"
                  :class="value === option.value ? 'opacity-100' : 'opacity-0'"
                />
                <slot name="item" v-bind="{ option, value }">
                  <span>{{ option.label }}</span>
                </slot>
              </div>
            </UiCommandItem>
          </UiCommandGroup>
        </UiCommandList>
      </UiCommand>
    </UiPopoverContent>
  </UiPopover>
</template>

<script setup lang="ts" generic="T extends FieldSelectOption">
import { useVModel } from "@vueuse/core";

type Options = T[];

const props = defineProps<{
  placeholder: string;
  options: Options; // Ensures at least `value` and `label`
  modelValue?: string;

  id?: string;
  ariaDescribedby?: string;
  ariaInvalid?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
  (e: "input", event: InputEvent): void;
}>();

const value = useVModel(props, "modelValue", emit);
const isOpen = ref(false);

const sortedOptions = computed(() => {
  const options = [...props.options]; // Clone the array to avoid mutation
  const selectedIdx = options.findIndex(
    (option) => option.value === value.value,
  );

  const selectedOption =
    selectedIdx >= 0 ? options.splice(selectedIdx, 1)[0] : undefined;

  if (selectedOption) {
    options.unshift(selectedOption);
  }

  return options;
});

const selectedOption = computed(() =>
  sortedOptions.value.find((option) => option.value === value.value),
);

const filterFunction = (val: T[], term: string): T[] => {
  return val.filter((option) =>
    option.label.toLowerCase().includes(term.toLowerCase()),
  );
};
</script>
