<template>
  <div class="relative p-4">
    <div class="absolute inset-x-4 inset-y-0 -z-10 flex">
      <div class="flex w-1/3" />
      <div class="flex w-1/3 px-2">
        <div class="w-full rounded-xl bg-secondary" />
      </div>
      <div class="flex w-1/3 px-2">
        <div class="border-ultra w-full rounded-xl" />
      </div>
    </div>
    <div class="mx-auto grid" :style="{
      'grid-template-columns': `repeat(${plans.length + 1}, minmax(0, 1fr))`,
    }">
      <section>
        <div :style="{ order: 0 }" />

        <!-- heading -->
        <div class="relative mx-2 flex flex-col justify-end px-6" :style="{ order: 1 }" />

        <template v-for="group in benefits">
          <template v-for="(item, j) in group.items" :key="j">
            <div class="mx-2 flex flex-col justify-end px-6" :style="{ order: 2 + j }" aria-hidden="true">
              <div class="flex items-center space-x-3 border-white/10 py-2" :class="{
                'border-b': j < group.items.length - 1,
              }">
                <template v-if="item.icon">{{ j }}
                  <Icon v-if="item.icon.name" :size="item.icon.size" :name="item.icon.name" :class="item.icon.class" />
                  <component :is="item.icon.component" v-else :class="item.icon.class" />
                </template>
                <span>
                  {{ item.label }}
                </span>
              </div>
            </div>
          </template>
        </template>

        <!-- button -->
        <div class="relative mx-2 flex flex-col justify-end px-6" :style="{ order: 2 + totalBenefitsCount }" />
      </section>
      <!-- End: Column with labels -->

      <template v-for="(plan, i) in plans" :key="i">
        <section>
          <div :style="{ order: 0 }" />
          <!-- heading -->
          <div class="relative mx-2 flex flex-col justify-end px-6" :style="{ order: 1 }">
            <div class="mb-5 grow">
              <div class="mt-2 flex items-center justify-center">
                <div class="-mt-1 inline-flex items-center space-x-2 py-2">
                  <IconUltra v-if="i === 1" class="h-8 w-8" />
                  <IconStreamlabs v-else class="h-8 w-8" />
                  <span class="mt-1 text-xl font-medium tracking-wide" :class="{ 'text-ultra': i === 1 }">
                    {{ plan.label }}
                  </span>
                </div>
              </div>
              <div class="mb-1 mt-4">
                <span class="text-2xl font-bold">{{ plan.price }}</span>
                <span v-if="plan.per" class="font-bold"> /{{ plan.per }} </span>
              </div>
              <div class="text-sm opacity-70">
                {{ plan.description }}
              </div>
            </div>
          </div>

          <template v-for="group in benefits">
            <!-- <div
            class="mx-2 flex flex-col justify-end bg-secondary px-4"
            :style="{
              order: ++order,
            }"
          >
            <div class="mt-4 py-2 font-medium md:sr-only">
              {{ group.label }}
            </div>
          </div> -->

            <template v-for="(item, j) in group.items" :key="j">
              <div class="mx-2 flex flex-col justify-end px-4" :style="{ order: 2 + j }">
                <div class="flex h-full items-center justify-center border-white/10 py-2" :class="{
                  'border-b': j < group.items.length - 1,
                }">
                  <Icon v-if="item.values[i] === true" name="lucide:check" size="1.25rem" />
                  <Icon v-else-if="item.values[i] === false" name="lucide:minus" size="1.25rem" class="opacity-50" />
                  <span v-else>{{ item.values[i] }}</span>
                </div>
              </div>
            </template>
          </template>

          <!-- button -->
          <div class="relative mx-2 flex flex-col justify-end px-6" :style="{ order: 2 + totalBenefitsCount }">
            <div class="mb-2 mt-10 w-full">
              <Button class="w-full" size="lg" :variant="i === 1 ? 'ultra' : undefined" @click="plan.onClick">
                Choose {{ plan.label }}
              </Button>
            </div>
          </div>
        </section>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import IconCloudbot from "~/components/icons/IconCloudbot.vue";
import IconMultistream from "~/components/icons/IconMultistream.vue";
import IconOverlays from "~/components/icons/IconOverlays.vue";
import IconTipping from "~/components/icons/IconTipping.vue";
import IconUltra from "~/components/icons/IconUltra.vue";
import IconWidgets from "~/components/icons/IconWidgets.vue";

import { openBrowserTo } from "~/lib/bindings";
import { getUltraUrl } from "~/lib/ultra";

const { track: $track } = useTrackingStore();

const emit = defineEmits(["complete"]);

const plans = [
  {
    label: "Starter",
    price: "Free",
    description: "Everything you need to go live. Always and forever free.",
    onClick: () => {
      $track("onboarding_free");
      emit("complete");
    },
  },
  {
    label: "Ultra",
    price: "$19",
    per: "month",
    description: "One plan, all the premium features",
    onClick: () => {
      $track("onboarding_ultra");
      openBrowserTo(getUltraUrl({ refl: "obsplugin-onboarding" }));
      emit("complete");
    },
  },
];

const benefits = [
  {
    label: "Group 1",
    items: [
      {
        label: "Overlays",
        values: ["Free Only", "Premium Library"],
        icon: {
          component: IconOverlays,
          class: "h-5 w-5 opacity-70",
        },
      },

      {
        label: "Widget Themes",
        icon: {
          name: "fa6-solid:brush",
          size: "1.25rem",
          class: "opacity-70",
        },
        values: ["Free Only", "Premium Library"],
      },
      {
        label: "Accept tips",
        icon: {
          component: IconTipping,
          class: "w-5 h-5 opacity-70",
        },
        values: [true, true],
      },
      {
        label: "Cloudbot",
        icon: {
          component: IconCloudbot,
          class: "w-5 h-5 opacity-70",
        },
        values: [true, true],
      },
      {
        label: "Alerts & Widgets",
        icon: {
          component: IconWidgets,
          class: "w-5 h-5 opacity-70",
        },
        values: [true, true],
      },

      {
        label: "Multistream",
        values: [false, true],
        icon: {
          component: IconMultistream,
          class: "h-5 w-5 opacity-70",
        },
      },
      {
        label: "More premium tools",
        values: [false, true],
        icon: {
          component: IconUltra,
          class: "h-5 w-5 opacity-70",
        },
      },

      //   {
      //     label: "Free Overlays",
      //     values: [true, true],
      //   },
      //   {
      //     label: "Premium Overlays",
      //     values: [false, true],
      //   },
      //   {
      //     label: "Multistream",
      //     values: [false, true],
      //   },
      //   {
      //     label: "More premium tools",
      //     values: [false, true],
      //   },
    ],
  },
  //   {
  //     label: "Group 2",
  //     items: [
  //       {
  //         label: "Perk #1",
  //         values: [400, 800, "Unlimited"],
  //       },
  //       {
  //         label: "Perk #2",
  //         values: [400, 800, "Unlimited"],
  //       },
  //     ],
  //   },
];

const totalBenefitsCount = computed(() => {
  return benefits.reduce((c, { items }) => c + items.length, 0);
});
</script>

<style lang="scss" scoped>
section {
  //   @apply md:contents [&>div:first-child]:rounded-t-2xl [&>div:first-child]:pt-10 [&>div:last-child]:rounded-b-2xl [&>div:last-child]:pb-10;
  @apply md:contents [&>div:first-child]:rounded-t-2xl [&>div:last-child]:rounded-b-2xl;
}
</style>
