<template>
  <div class="w-full space-y-6 py-4">
    <template v-for="(fieldset, i) in fieldsets" :key="i">
      <div>
        <div v-if="fieldset.label" :class="formItemClasses">
          <div :class="labelClasses"></div>
          <div class="w-full font-bold">{{ fieldset.label }}</div>
        </div>

        <div>
          <template v-for="(field, i) in fieldset.fields" :key="i">
            <template v-if="field.type === 'checkbox_group'">
              <div :class="formItemClasses" class="space-y-2">
                <label
                  :class="labelClasses"
                  class="mt-5 w-[200px] pr-4 text-right text-sm font-medium leading-none opacity-80 peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  {{ field.label }}
                </label>
                <div class="w-full space-y-2 pt-2">
                  <template
                    v-for="subfield in field.fields"
                    :key="subfield.name"
                  >
                    <UiFormField
                      v-slot="{ value, handleChange }"
                      :name="subfield.name"
                    >
                      <UiFormItem
                        class="flex flex-row items-start space-x-3 space-y-0"
                      >
                        <UiFormControl>
                          <UiCheckbox
                            :id="subfield.name"
                            :checked="value"
                            @update:checked="handleChange"
                          />
                        </UiFormControl>

                        <UiFormLabel class="font-normal">
                          {{ subfield.label }}
                        </UiFormLabel>

                        <UiFormMessage />
                      </UiFormItem>
                    </UiFormField>
                  </template>
                </div>
              </div>
            </template>
            <template v-else>
              <UiFormField
                v-slot="{ value, handleChange, componentField }"
                :name="field.name"
              >
                <UiFormItem :class="formItemClasses">
                  <UiFormLabel :class="labelClasses">
                    {{ field.label }}
                  </UiFormLabel>
                  <div class="w-full">
                    <UiFormControl>
                      <template v-if="field.type === 'file'">
                        <YouTubeThumbnailInput
                          class="max-h-[120px] max-w-[400px]"
                          :allow-types="field.allowedTypes"
                          :max-size="field.maxSize"
                          :model-value="value"
                          @update:model-value="handleChange"
                        />
                      </template>
                      <template v-else-if="field.type === 'text'">
                        <Input
                          spellcheck="false"
                          type="text"
                          :placeholder="field.placeholder"
                          v-bind="componentField"
                        />
                      </template>
                      <template v-else-if="field.type === 'textarea'">
                        <Textarea
                          v-bind="componentField"
                          :placeholder="field.placeholder"
                        />
                      </template>

                      <template v-else-if="field.type === 'checkbox'">
                        <UiCheckbox
                          :id="field.name"
                          :class="{
                            'mt-[9px]': field.label,
                          }"
                          :checked="value"
                          @update:checked="handleChange"
                        />
                        <!-- <UiSwitch
                              :checked="value"
                              @update:checked="handleChange"
                            /> -->
                      </template>

                      <template v-else-if="field.type === 'select'">
                        <UiSelect v-bind="componentField">
                          <UiSelectTrigger>
                            <UiSelectValue :placeholder="field.placeholder" />
                          </UiSelectTrigger>
                          <UiSelectContent>
                            <UiSelectGroup>
                              <!-- <UiSelectLabel>Privacy</UiSelectLabel> -->
                              <UiSelectItem
                                v-for="option in isRef(field.options)
                                  ? unref(field.options)
                                  : field.options"
                                :key="option.value"
                                :value="option.value"
                              >
                                {{ option.label }}
                              </UiSelectItem>
                            </UiSelectGroup>
                          </UiSelectContent>
                        </UiSelect>
                      </template>

                      <template v-else-if="field.type === 'combo'">
                        <ComboInput
                          :placeholder="field.placeholder"
                          :options="unref(field.options)"
                          :model-value="value"
                          @update:model-value="handleChange"
                        />
                      </template>

                      <template v-else-if="field.type === 'twitch_tags'">
                        <UiTagsInput
                          :model-value="value"
                          class="w-full"
                          @update:model-value="handleChange"
                        >
                          <UiTagsInputItem
                            v-for="item in value"
                            :key="item"
                            :value="item"
                            :class="{
                              'bg-red-600/40 text-red-200':
                                !twitchTagSchema.safeParse(item).success,
                            }"
                          >
                            <UiTagsInputItemText />
                            <UiTagsInputItemDelete />
                          </UiTagsInputItem>

                          <UiTagsInputInput
                            :placeholder="
                              value.length
                                ? 'Tag...'
                                : `Hit 'Enter' after each tag...`
                            "
                          />
                        </UiTagsInput>
                      </template>
                      <template v-else-if="field.type === 'twitch_category'">
                        <TwitchCategoryCombo
                          :model-value="value"
                          @update:model-value="handleChange"
                        />
                      </template>
                      <template v-else-if="field.type === 'tiktok_category'">
                        <TwitchCategoryCombo
                          :model-value="value"
                          @update:model-value="handleChange"
                          query-path="/api/v5/obs-plugin/go-live/tiktok/info"
                          :default-categories="[]"
                          :map-item="
                            (item) => ({
                              name: item.full_name as string,
                              id: item.game_mask_id as string,
                            })
                          "
                        />
                      </template>
                      <template v-else-if="field.type === 'kick_category'">
                        <TwitchCategoryCombo
                          :model-value="value"
                          @update:model-value="handleChange"
                          query-path="/api/v5/obs-plugin/go-live/kick/info"
                          :default-categories="[]"
                          :map-item="
                            (item) => {
                              return {
                                thumbnail: item.thumbnail as string,
                                name: item.name as string,
                                id: `${item.id}`,
                              };
                            }
                          "
                        />
                      </template>
                    </UiFormControl>

                    <template
                      v-if="field.type === 'checkbox' && field.field_label"
                    >
                      <UiFormLabel class="ml-3 font-normal">
                        {{ field.field_label }}
                      </UiFormLabel>
                    </template>

                    <UiFormMessage />
                  </div>
                </UiFormItem>
              </UiFormField>
            </template>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
const formItemClasses = "flex";
const labelClasses = ["text-right", "pr-4", "mt-5", "w-[200px]", "opacity-80"];

defineProps<{ fieldsets: Fieldset[] }>();
</script>
